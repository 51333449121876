@keyframes instantCollapseSpace {
    100% {
        width: 78px;
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    60% {
        opacity: 0 
    }
    
    100% {
        opacity: 1;
    }
}

/* Container that occupied space */
.side-menu-container {
    display: block;
    width: 320px;
    height: 100%;
}

.instant-collapse {
    animation: instantCollapseSpace 0.1s forwards;
    /* Will wait until side-menu animation is done */
    animation-delay: 0.15s;
}


.side-menu {
    width: 320px; 
    height: 100%;
    min-height: 100vh;
    top: 0; 
    left: 0; 
    bottom: 0; 
    box-shadow: rgba(0,0,0,.1) 02px 0 5px 6px;
    overflow: hidden; 
    transition: width 0.2s 0.1s cubic-bezier(0.0, 0.0, 0.2, 1); 
    position: fixed;
    z-index: 2;
}

.side-menu.collapsed {
    opacity: 1;
    width: 78px;
}

.content {
    opacity: 1; 
    transition: opacity 0.2s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.content.fade-out {
    opacity: 0;
}

.divide-line {
    width: 90%; 
    height: 1px;
    background: #6C768E;
    position: absolute;
    top: 140px; 
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.2s cubic-bezier(0.0, 0.0, 0.2, 1);
    opacity: 1;
}

.divide-line.collapsed {
    opacity: 0;
}

/* a overlay to prevent unwanted user interaction */
.toggle-btn-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;

}
.toggle-btn {
    position: absolute; 
    cursor: pointer;
    z-index: 2;
    display: flex; 
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.1s linear;
}

.toggle-btn-icon {
    width: 28px;
    height: 20px; 
    margin: 8px;
}

.toggle-btn.collapse {
    color: #7F9AC4;
    top: 112px; 
    right: 16px; 
}

.toggle-btn.expand {
    color: #FFFFFF;
    top: 8px; 
    right: 16px;
    left: 50%; 
    transform: translateX(-50%);
}

.toggle-btn .arrow {
    width: 12px; 
    margin-right: 4px; 
}

.toggle-btn .text-container {
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 11px;
    white-space: nowrap;
}

.toggle-btn.fade-in-out {
    animation: fadeInOut 0.3s linear forwards;
}