/* CSS file: LoginInput.css */

.login-input {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.login-input label {
  position: absolute;
  left: 8px;
  top: 8px;
  transition: all 0.2s ease-in-out;
  color: #838da4;
  white-space: nowrap; 
  font-size: 11px;
}

.login-input .label-focus {
  top: -18px;
  font-size: 12px;
  color: #838da4;
  transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease; /* Smooth animation */
}

.login-input input {
  /* eye icon is 18px width */
  width: calc(100% - 54px);
  padding: 10px;
  border: none; /* Transparent color */
  border-radius: 0; /* Remove border radius */
  background-color: transparent; /* Transparent color */
  color: #fff;
}

.login-input input:focus {
  outline: none;
  /* border-bottom: none; */
}

.login-input .input-row-container {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #838da4; /* Only bottom border with color = #838DA4 */
  display: flex;
  align-items: center;
  padding-left: 12px;
}

.login-input .input {
  position: relative;
  width: 100%;
}

.login-input .show-container {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.login-input .show-container img {
  width: 18px;
  height: 18px;
}

.login-input .vertical-divide-line {
  width: 2px;
  height: 25.5px;
  background-color: #838da4;
  margin: 0 8px;
}

.login-input .vertical-divide-line.valid {
  background-color: #00ff00;
}

.login-input .valid {
  border-color: #00ff00;
}

.login-input .icon-container {
  width: 20px;
  height: 20px;
}

.login-input .icon {
  width: 20px;
  height: 20px;
}

/* .login-input .icon.password {
    width: 18px; 
    height: 20px;
  } */

.login-input .icon img {
  width: 100%;
  height: 100%;
}
