.login-page {
  width: 100vw;
  /* fallback for dvh */
  height: 100vh; 
  height: 100dvh;
  background: #232632;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  overflow: auto;
  padding: 32px;
}

.login-page .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 411px;
  max-height: 100%;
}

.login-page .logo-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.login-page .main-logo-area {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.login-page .main-logo-area .logo {
  width: 75px;
  height: 68px;
  margin-right: 18px;
}

.login-page .main-logo-area .logo img,
.login-page .main-logo-area .main-title img,
.login-page .sub-logo-area .sub-title img,
.login-page .submit-btn img {
  width: 100%;
}

.login-page .main-logo-area .main-title {
  width: 185px;
  height: 87px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page .sub-logo-area .sub-title {
  width: 248px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page .fields-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.login-page .submit-btn {
  width: 64px;
  height: 64px;
  cursor: pointer;
}

.login-page .submit-btn:active {
  transform: translateY(4px);
}

.login-page .login-field {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.login-page .forgot-password-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
